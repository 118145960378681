import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  where,
} from 'firebase/firestore'
import { auth, db } from './Firebase'
import { getAuth, signInWithEmailAndPassword, updateProfile } from 'firebase/auth'
import { endOfDay, startOfDay } from 'date-fns'
import { mostrarMensajeError } from './Mensajes'

export const validarSesion = (setValidarSesion) => {
  try {
    auth.onAuthStateChanged((user) => {
      setValidarSesion(user)
    })
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
}

export const iniciarSesion = async (email, password) => {
  const auth = getAuth()
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
    return user
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
}

export const signOutUser = async () => {
  try {
    await auth.signOut()
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
}

export const ObtenerUsuario = () => {
  return auth.currentUser
}

export const actualizarPerfil = async (data) => {
  try {
    await updateProfile(ObtenerUsuario(), data)
    return true
  } catch (error) {
    mostrarMensajeError('Oops...', error)
    return false
  }
}

export const addRegistro = async (coleccion, data) => {
  const reference = collection(db, coleccion)

  try {
    const newDoc = await addDoc(reference, data)
    return { error: '', statusresponse: true, id: newDoc.id }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
    return { error, statusresponse: false, id: '' }
  }
}

export const addRegistroEspecifico = async (coleccion, path, data) => {
  const reference = doc(db, coleccion, path)

  try {
    await setDoc(reference, data, { merge: true })
    return { error: '', statusresponse: true }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
    return { error, statusresponse: false }
  }
}

export const eliminarData = async (coleccion, documento) => {
  const reference = doc(db, coleccion, documento)

  try {
    await deleteDoc(reference)
    return { error: '', statusresponse: true }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
    return { error, statusresponse: false }
  }
}

export async function getAdminUsers() {
  const adminUsersCollection = collection(db, 'Administradores')
  const adminUsersQuery = query(adminUsersCollection)

  try {
    const adminUsersSnapshot = await getDocs(adminUsersQuery)
    const adminUsersList = adminUsersSnapshot.docs.map((doc) => doc.data())
    return adminUsersList
  } catch (error) {
    mostrarMensajeError('Oops...', error)
    return []
  }
}

export const ListarClientes = async () => {
  const response = { statusresponse: false, data: [] }
  try {
    const q = query(collection(db, 'Usuarios'), where('type', '==', 'Comprador'))
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const usuario = doc.data()
      usuario.id = doc.id
      response.data.push(usuario)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const ListarVendedores = async () => {
  const response = { statusresponse: false, data: [] }
  try {
    const q = query(
      collection(db, 'Usuarios'),
      where('type', '==', 'Vendedor'),
      where('status', '==', 2),
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const usuario = doc.data()
      usuario.id = doc.id
      response.data.push(usuario)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const ListarRepartidores = async () => {
  const response = { statusresponse: false, data: [] }
  try {
    const q = query(
      collection(db, 'Usuarios'),
      where('type', '==', 'Repartidor'),
      where('status', '==', 2),
    )
    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
      const usuario = doc.data()
      usuario.id = doc.id
      response.data.push(usuario)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const SolicitudesDeVendedores = async (startDate, endDate) => {
  const response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const usuariosQuery = query(
    collection(db, 'Usuarios'),
    where('type', '==', 'Vendedor'),
    where('status', '==', 1),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    orderBy('fechaCreacion', 'desc'),
  )

  try {
    const usuariosSnapshot = await getDocs(usuariosQuery)

    usuariosSnapshot.forEach((doc) => {
      const usuario = doc.data()
      usuario.id = doc.id
      response.data.push(usuario)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const SolicitudesDeRepartidores = async (startDate, endDate) => {
  const response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const usuariosQuery = query(
    collection(db, 'Usuarios'),
    where('type', '==', 'Repartidor'),
    where('status', '==', 1),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    orderBy('fechaCreacion', 'desc'),
  )

  try {
    const usuariosSnapshot = await getDocs(usuariosQuery)

    usuariosSnapshot.forEach((doc) => {
      const usuario = doc.data()
      usuario.id = doc.id
      response.data.push(usuario)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

const obtenerAyuda = async (coleccion) => {
  const response = { statusresponse: false, data: [] }

  try {
    const ayudaQuery = collection(db, coleccion)
    const ayudaSnapshot = await getDocs(ayudaQuery)

    ayudaSnapshot.forEach((doc) => {
      const ayuda = doc.data()
      ayuda.id = doc.id
      response.data.push(ayuda)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const AyudaParaClientes = async () => obtenerAyuda('AyudaClientes')
export const AyudaParaVendedores = async () => obtenerAyuda('AyudaVendedores')
export const AyudaParaRepartidores = async () => obtenerAyuda('AyudaRepartidores')

const obtenerUsuarios = async (type, status, startDate, endDate) => {
  const response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const usuariosQuery = status
    ? query(
        collection(db, 'Usuarios'),
        where('type', '==', type),
        where('status', '==', status),
        where('fechaCreacion', '>=', adjustedStartDate),
        where('fechaCreacion', '<=', adjustedEndDate),
        orderBy('fechaCreacion', 'desc'),
      )
    : query(
        collection(db, 'Usuarios'),
        where('type', '==', type),
        where('fechaCreacion', '>=', adjustedStartDate),
        where('fechaCreacion', '<=', adjustedEndDate),
        orderBy('fechaCreacion', 'desc'),
      )

  try {
    const usuariosSnapshot = await getDocs(usuariosQuery)

    usuariosSnapshot.forEach((doc) => {
      const usuario = doc.data()
      usuario.id = doc.id
      response.data.push(usuario)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const AdministrarVendedores = async (startDate, endDate) =>
  obtenerUsuarios('Vendedor', 2, startDate, endDate)
export const AdministrarRepartidores = async (startDate, endDate) =>
  obtenerUsuarios('Repartidor', 2, startDate, endDate)
export const AdministrarConsumidores = async (startDate, endDate) =>
  obtenerUsuarios('Comprador', null, startDate, endDate)

export const obternerRegistroxID = async (coleccion, documento) => {
  let response = { statusresponse: false, data: null }

  try {
    const docRef = doc(db, coleccion, documento)
    const docSnapshot = await getDoc(docRef)

    if (docSnapshot.exists()) {
      const visita = docSnapshot.data()
      visita.id = docSnapshot.id
      response.data = visita
      response.statusresponse = true
    } else {
      console.log('Documento no encontrado.')
    }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const ObtenerFacturasVendedores = async () => {
  let response = { statusresponse: false, data: [] }

  try {
    const q = query(
      collection(db, 'RegistroComisiones'),
      where('factura', '==', 2),
      orderBy('fechaSolicitud', 'desc'),
    )

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      let facturas = doc.data()
      facturas.id = doc.id
      response.data.push(facturas)
    })

    if (response.data.length > 0) {
      response.statusresponse = true
    } else {
      response.statusresponse = false
    }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }
  return response
}

export const ObtenerFacturasRepartidores = async () => {
  let response = { statusresponse: false, data: [] }

  try {
    const q = query(
      collection(db, 'RegistroComisionesRepartidores'),
      where('factura', '==', 2),
      orderBy('fechaSolicitud', 'desc'),
    )

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      let facturas = doc.data()
      facturas.id = doc.id
      response.data.push(facturas)
    })

    if (response.data.length > 0) {
      response.statusresponse = true
    } else {
      response.statusresponse = false
    }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const ObtenerCarritoGeneral = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  try {
    const q = query(
      collection(db, 'Carrito'),
      where('fechacreacion', '>=', adjustedStartDate),
      where('fechacreacion', '<=', adjustedEndDate),
      orderBy('fechacreacion', 'desc'),
    )

    const querySnapshot = await getDocs(q)

    querySnapshot.forEach((doc) => {
      let carrito = doc.data()
      carrito.id = doc.id
      response.data.push(carrito)
    })

    if (response.data.length > 0) {
      response.statusresponse = true
    } else {
      response.statusresponse = false
    }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const carritosPendientes = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'Carrito'),
    where('mensaje', '==', 'Esperando al restaurante'),
    where('status', '==', 'enviado'),
    where('fechacreacion', '>=', adjustedStartDate),
    where('fechacreacion', '<=', adjustedEndDate),
    orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const carritos = snapshot.docs.map((data) => {
      const carrito = data.data()
      carrito.id = data.id
      return carrito
    })

    if (carritos.length > 0) {
      response.statusresponse = true
    }

    response.data = carritos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const carritosConfirmados = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'Carrito'),
    where('status', '==', 'enviado'),
    where('statusPedido', '==', 2),
    where('fechacreacion', '>=', adjustedStartDate),
    where('fechacreacion', '<=', adjustedEndDate),
    orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const carritos = snapshot.docs.map((data) => {
      const carrito = data.data()
      carrito.id = data.id
      return carrito
    })

    if (carritos.length > 0) {
      response.statusresponse = true
    }

    response.data = carritos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const carritosSinRepartidores = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  try {
    const q = query(
      collection(db, 'Carrito'),
      where('mensaje', '==', 'Se está preparando tu orden'),
      where('tipoEntrega', '==', 'A domicilio'),
      where('status', '==', 'enviado'),
      where('statusPedido', '==', 2),
      where('fechacreacion', '>=', adjustedStartDate),
      where('fechacreacion', '<=', adjustedEndDate),
      orderBy('fechacreacion', 'desc'),
    )

    const snapshot = await getDocs(q)
    snapshot.forEach((doc) => {
      const carrito = doc.data()
      carrito.id = doc.id
      response.data.push(carrito)
    })

    if (response.data.length > 0) {
      response.statusresponse = true
    }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const carritosEnRuta = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'Carrito'),
    where('tipoEntrega', '==', 'A domicilio'),
    where('status', '==', 'enviado'),
    where('statusPedido', '==', 2),
    where('fechacreacion', '>=', adjustedStartDate),
    where('fechacreacion', '<=', adjustedEndDate),
    orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const carritos = snapshot.docs.map((data) => {
      const carrito = data.data()
      carrito.id = data.id
      return carrito
    })

    if (carritos.length > 0) {
      response.statusresponse = true
    }

    response.data = carritos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const carritosRecogerPedido = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'Carrito'),
    where('tipoEntrega', '==', 'Recoger pedido'),
    where('fechacreacion', '>=', adjustedStartDate),
    where('fechacreacion', '<=', adjustedEndDate),
    orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const carritos = snapshot.docs.map((data) => {
      const carrito = data.data()
      carrito.id = data.id
      return carrito
    })

    if (carritos.length > 0) {
      response.statusresponse = true
    }

    response.data = carritos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const carritosConcluidos = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'Carrito'),
    where('status', '==', 'Concluido'),
    where('fechacreacion', '>=', adjustedStartDate),
    where('fechacreacion', '<=', adjustedEndDate),
    orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const carritos = snapshot.docs.map((data) => {
      const carrito = data.data()
      carrito.id = data.id
      return carrito
    })

    if (carritos.length > 0) {
      response.statusresponse = true
    }

    response.data = carritos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const eventosGeneral = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  // const adjustedStartDate = startOfDay(startDate)
  // const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'EventosAgendados'),
    // where('fechacreacion', '>=', adjustedStartDate),
    // where('fechacreacion', '<=', adjustedEndDate),
    // orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const eventos = snapshot.docs.map((data) => {
      const evento = data.data()
      evento.id = data.id
      return evento
    })

    if (eventos.length > 0) {
      response.statusresponse = true
    }

    response.data = eventos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const eventosPendientes = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  // const adjustedStartDate = startOfDay(startDate)
  // const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'EventosAgendados'),
    where('statusEvento', '==', 1),
    where('status', '==', 1),
    // where('fechacreacion', '>=', adjustedStartDate),
    // where('fechacreacion', '<=', adjustedEndDate),
    // orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const eventos = snapshot.docs.map((data) => {
      const evento = data.data()
      evento.id = data.id
      return evento
    })

    if (eventos.length > 0) {
      response.statusresponse = true
    }

    response.data = eventos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const eventosAceptados = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  // const adjustedStartDate = startOfDay(startDate)
  // const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'EventosAgendados'),
    where('statusEvento', '==', 3),
    where('status', '==', 1),
    // where('fechacreacion', '>=', adjustedStartDate),
    // where('fechacreacion', '<=', adjustedEndDate),
    // orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const eventos = snapshot.docs.map((data) => {
      const evento = data.data()
      evento.id = data.id
      return evento
    })

    if (eventos.length > 0) {
      response.statusresponse = true
    }

    response.data = eventos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const eventosConcluidos = async (startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  // const adjustedStartDate = startOfDay(startDate)
  // const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'EventosAgendados'),
    where('status', '==', 2),
    // where('fechacreacion', '>=', adjustedStartDate),
    // where('fechacreacion', '<=', adjustedEndDate),
    // orderBy('fechacreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const eventos = snapshot.docs.map((data) => {
      const evento = data.data()
      evento.id = data.id
      return evento
    })

    if (eventos.length > 0) {
      response.statusresponse = true
    }

    response.data = eventos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const obtenerProspectos = async (coleccion, tipoUsuario, startDate, endDate) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, coleccion),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    where('tipoUsuario', '==', tipoUsuario),
    orderBy('fechaCreacion', 'desc'),
  )

  try {
    const snapshot = await getDocs(q)
    const prospectos = snapshot.docs.map((data) => {
      const prospecto = data.data()
      prospecto.id = data.id
      return prospecto
    })

    if (prospectos.length > 0) {
      response.statusresponse = true
    }

    response.data = prospectos
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const obtenerNotificaciones = async (coleccion, startDate, endDate, tipoUsuario = null) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  let q = query(
    collection(db, coleccion),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    orderBy('fechaCreacion', 'desc'),
  )

  if (tipoUsuario !== null) {
    q = query(q, where('tipoUsuario', '==', tipoUsuario))
  }

  try {
    const snapshot = await getDocs(q)
    const notificaciones = snapshot.docs.map((data) => {
      const notificacion = data.data()
      notificacion.id = data.id
      return notificacion
    })

    if (notificaciones.length > 0) {
      response.statusresponse = true
    }

    response.data = notificaciones
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const obtenerCategorias = async (coleccion, startDate, endDate, activa = null) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  let q = query(
    collection(db, coleccion),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    orderBy('fechaCreacion', 'desc'),
  )

  if (activa !== null) {
    q = query(q, where('activa', '==', activa))
  }

  try {
    const snapshot = await getDocs(q)
    const categorias = snapshot.docs.map((data) => {
      const categoria = data.data()
      categoria.id = data.id
      return categoria
    })

    if (categorias.length > 0) {
      response.statusresponse = true
    }

    response.data = categorias
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const obtenerCupones = async (coleccion, startDate, endDate, activa = null) => {
  let response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  let q = query(
    collection(db, coleccion),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    orderBy('fechaCreacion', 'desc'),
  )

  if (activa !== null) {
    q = query(q, where('activa', '==', activa))
  }

  try {
    const snapshot = await getDocs(q)
    const cupones = snapshot.docs.map((data) => {
      const cupon = data.data()
      cupon.id = data.id
      return cupon
    })

    if (cupones.length > 0) {
      response.statusresponse = true
    }

    response.data = cupones
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}

export const obtenerMovimientos = async (startDate, endDate) => {
  const response = { statusresponse: false, data: [] }

  const adjustedStartDate = startOfDay(startDate)
  const adjustedEndDate = endOfDay(endDate)

  const q = query(
    collection(db, 'MovimientosAdmin'),
    where('fechaCreacion', '>=', adjustedStartDate),
    where('fechaCreacion', '<=', adjustedEndDate),
    orderBy('fechaCreacion', 'desc'),
  )

  try {
    const movimientos = await getDocs(q)

    movimientos.forEach((doc) => {
      const movimiento = doc.data()
      movimiento.id = doc.id
      response.data.push(movimiento)
    })

    response.statusresponse = response.data.length > 0
  } catch (error) {
    mostrarMensajeError('Oops...', error)
  }

  return response
}
export const crearMovimiento = async (coleccion, data, accion) => {
  const dataConAccion = {
    ...data,
    dataMovimiento: {
      accion: accion,
      coleccion: coleccion,
      fechaCreacion: new Date(),
      usuario: auth?.currentUser?.uid,
      email: auth?.currentUser?.email,
    },
  }

  try {
    const newDoc = await addRegistro(coleccion, dataConAccion)
    return { error: '', statusresponse: true, id: newDoc.id }
  } catch (error) {
    mostrarMensajeError('Oops...', error)
    return { error, statusresponse: false, id: '' }
  }
}

import { initializeApp } from 'firebase/app'
import { initializeAuth, browserLocalPersistence } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyAF_3CP1mcILga1Pc3iDF8LlVRdnANqM0I',
  authDomain: 'hapsay-4a648.firebaseapp.com',
  projectId: 'hapsay-4a648',
  storageBucket: 'hapsay-4a648.appspot.com',
  messagingSenderId: '167275648800',
  appId: '1:167275648800:web:bde0a58e606c72cb6ce945',
  measurementId: 'G-6VHEPGGSV5',
}

export const firebaseApp = initializeApp(firebaseConfig)
export const auth = initializeAuth(firebaseApp, { persistence: browserLocalPersistence })
export const db = getFirestore(firebaseApp)

import React, { useEffect, useState } from 'react'
import { CSpinner } from '@coreui/react'
import logo from 'src/assets/brand/Logo_ColorOriginal.png'
import '../../../scss/_custom.scss'

const LoadingHome = () => {
  const [imageUp, setImageUp] = useState(true)

  useEffect(() => {
    const toggleImagePosition = () => {
      setImageUp((prevImageUp) => !prevImageUp)
    }

    const interval = setInterval(toggleImagePosition, 500)

    toggleImagePosition()

    return () => {
      clearInterval(interval)
    }
  }, [])

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  }

  const spinnerStyle = {
    marginTop: '20px',
  }

  const sombraStyle = {
    width: imageUp ? '60px' : '100px',
    background: imageUp ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.3)',
  }

  const imagenStyle = {
    transform: imageUp ? 'translateY(-20px)' : 'translateY(0)',
  }

  return (
    <div style={containerStyle}>
      <div className="contenedor-imagen">
        <div className="sombra" style={sombraStyle}></div>
        <img src={logo} alt="Logo" className="imagen" style={imagenStyle} />
      </div>
      <CSpinner color="danger" style={spinnerStyle} />
    </div>
  )
}

export default LoadingHome

import Swal from 'sweetalert2'

export const mostrarMensajeExito = (titulo, descripcion) => {
  return Swal.fire({
    icon: 'success',
    title: titulo,
    text: descripcion,
    confirmButtonColor: '#e55353',
  })
}

export const mostrarMensajeError = (titulo, descripcion) => {
  return Swal.fire({
    icon: 'error',
    title: titulo,
    text: descripcion,
    confirmButtonColor: '#e55353',
  })
}

export const mostrarMensajePregunta = (title, descripcion) => {
  return Swal.fire({
    title: title,
    text: descripcion,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#e55353',
    confirmButtonText: 'Aceptar',
  })
}

import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import { getAdminUsers, signOutUser, validarSesion } from './utils/Acciones'
import LoadingHome from './views/pages/loadingHome/LoadingHome'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  const [user, setUser] = useState(null)
  const [adminUserData, setAdminUserData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    validarSesion((userData) => {
      setUser(userData)

      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
    })
  }, [])

  useEffect(() => {
    const fetchAdminUsers = async () => {
      try {
        if (user) {
          const adminUsers = await getAdminUsers()
          const matchingAdminUser = adminUsers.find((admin) => admin.email === user.email)

          if (matchingAdminUser) {
            setAdminUserData(matchingAdminUser)
          } else {
            await signOutUser()
          }
        }
      } catch (error) {
        console.error('Error al obtener usuarios administradores', error)
      }
    }

    fetchAdminUsers()
  }, [user])

  if (isLoading) {
    return <LoadingHome />
  }

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path="*"
            element={
              user ? (
                <DefaultLayout user={user} adminUserData={adminUserData} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route path="/login" element={user ? <Navigate to="/" /> : <Login />} />
          <Route path="/register" element={user ? <Navigate to="/" /> : <Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
